import { useState } from "react";
import type { FC, ReactNode } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button, IconButton, Collapse, ListItem } from "@mui/material";
import type { ListItemProps } from "@mui/material";
import ChevronDownIcon from "src/icons/ChevronDown";
import ChevronRightIcon from "src/icons/ChevronRight";
import useCollapseDrawer from "src/utils/hooks/useCollapseDrawer";

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  rightData?: ReactNode;
}

const NavItem: FC<NavItemProps> = (props: NavItemProps) => {
  const {
    active = false,
    children = null,
    depth,
    icon = null,
    info = null,
    open: openProp = false,
    path = "",
    title,
    rightData = null,
  } = props;
  const [open, setOpen] = useState<boolean>(openProp);
  const { isCollapse } = useCollapseDrawer();

  const handleToggle = (): void => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children && !isCollapse) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
        }}
      >
        <Button
          endIcon={
            !open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronDownIcon fontSize="small" />
            )
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: "text.secondary",
            fontWeight: 400,
            justifyContent: "flex-start",
            gap: "12px",
            pl: `${paddingLeft}px`,
            pr: "8px",
            height: "50px",
            my: "2px",
            textAlign: "start",
            textTransform: "none",
            width: "100%",
            borderRadius: "100px",
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }
  if (isCollapse)
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
        }}
      >
        <IconButton disabled color="primary">
          {icon}
        </IconButton>
      </ListItem>
    );
  // Leaf

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
    >
      <Button
        //@ts-ignore
        component={path && RouterLink}
        startIcon={icon}
        sx={{
          color: "text.secondary",
          fontWeight: 400,
          justifyContent: "flex-start",
          gap: "12px",
          textAlign: "left",
          pl: `${paddingLeft}px`,
          pr: "8px",
          height: "50px",
          my: "2px",
          textTransform: "none",
          width: "100%",
          borderRadius: "100px",
          ":hover": {
            color: (theme) => theme.palette.text.primary,
          },
          ...(active && {
            color: "#fff",
            background: "#186FD6",
            ":hover": {
              background: "#186FD6",
              color: "#fff",
            },
          }),
        }}
        variant="text"
        to={path}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        {info}
        {rightData}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  rightData: PropTypes.node,
};

export default NavItem;
