import type { FC } from "react";
import { matchPath } from "react-router-dom";
import { List, ListSubheader } from "@mui/material";
import type { ListProps } from "@mui/material";
import NavItem from "./NavItem";
import useCollapseDrawer from "../utils/hooks/useCollapseDrawer";
import { ISection, ISectionItem } from "./layouts/navbar/Sections";

interface NavSectionProps extends ListProps {
  section: ISection;
  pathname: string;
}

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: JSX.Element[];
  pathname: string;
  item: ISectionItem;
  depth: number;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname
        )
      : false;
    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        key={key}
        open={partialMatch}
        icon={item.icon}
        info={item.info}
        path={item.path}
        title={item.title}
        rightData={item.rightData}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        rightData={item.rightData}
      />
    );
  }

  return acc;
};
const renderNavItems = ({
  depth = 0,
  items,
  pathname,
}: {
  items: ISectionItem[];
  pathname: string;
  depth?: number;
}): JSX.Element => {
  return (
    <List disablePadding>
      {/* @ts-ignore */}
      {items.reduce((acc, item) => {
        return reduceChildRoutes({ acc, item, pathname, depth });
      }, [])}
    </List>
  );
};
const NavSection: FC<NavSectionProps> = (props: NavSectionProps) => {
  const { section, pathname } = props;

  const { isCollapse } = useCollapseDrawer();

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "text.primary",
            fontSize: "1rem",
            lineHeight: 2.5,
            fontWeight: 300,
            textTransform: "uppercase",
          }}
        >
          {!isCollapse && section.title}
        </ListSubheader>
      }
    >
      {renderNavItems({
        items: section.items,
        pathname,
      })}
    </List>
  );
};

export default NavSection;
