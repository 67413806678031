import { createSvgIcon } from "@mui/material/utils";

const Account = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="user_icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path id="Path_2474" data-name="Path 2474" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_2475"
      data-name="Path 2475"
      d="M12,17a10.68,10.68,0,0,1,8.607,3.925l-1.842.871A8.867,8.867,0,0,0,12,19a8.867,8.867,0,0,0-6.765,2.8l-1.841-.872A10.677,10.677,0,0,1,12,17ZM12,2a5,5,0,0,1,5,5v3A5,5,0,0,1,7,10V7a5,5,0,0,1,5-5Z"
      fill="#039be5"
    />
  </svg>,
  "Account"
);

export default Account;
