import type { FC } from "react";
import { Box } from "@mui/material";
import type { Theme, SxProps } from "@mui/material";

interface Props {
  sx?: SxProps<Theme>;
}

const Logo: FC<Props> = ({ sx }) => (
  <Box sx={{ ...sx }}>
    <img alt="HR Logo" width="100%" height="auto" src="/logo.png" />
  </Box>
);

export default Logo;
