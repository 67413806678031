import type { FC } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import type { AppBarProps } from "@mui/material";
import MenuIcon from "../../../icons/Menu";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import useCollapseDrawer from "../../../utils/hooks/useCollapseDrawer";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
  }),
  zIndex: 100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen } = props;
  const { isCollapse } = useCollapseDrawer();

  return (
    <DashboardNavbarRoot>
      <Toolbar
        sx={{
          minHeight: 64,
          backgroundColor: "background.default",
          justifyContent: {
            lg: "flex-end",
            xs: "space-between",
          },
        }}
      >
        <IconButton
          sx={{
            display: {
              lg: "none",
              xs: "block",
            },
          }}
          color="inherit"
          onClick={onSidebarMobileOpen}
        >
          <MenuIcon sx={{ color: "#000" }} fontSize="small" />
        </IconButton>
        <Box
          sx={{
            width: isCollapse ? "100%" : "calc(100% - 280px)",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: 3,
            }}
          >
            <NotificationsPopover />

            <AccountPopover />
          </Box>
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
