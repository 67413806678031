import axios from "axios";
import { getErrorMessageByCode } from "../../config/ServerErrors";
import { getJWT, removeJWT } from "../helpers/local-storage.helper";
import {
  showErrorSnackbarMessage,
  showWarningSnackbarMessage,
} from "../helpers/snackbar.helper";
import { getDeviceId } from "../helpers/device-detect.helper";

const axiosInstance = axios.create();

// for error handling
axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log("error.message\n", error?.message);
    console.log("error.message\n", error?.response?.data?.message);
    const ERROR_CODE = error?.response?.data?.error?.server_code;
    console.log("error.code\n", ERROR_CODE);
    if (error?.message === "Network Error") {
      showWarningSnackbarMessage("Network Error!");
    } else {
      const message = getErrorMessageByCode(ERROR_CODE);
      if (ERROR_CODE === 7) {
        removeJWT();
        window.location.href = "/login";
      }
      showErrorSnackbarMessage(message);
    }
    throw error;
  }
);

axiosInstance.interceptors.request.use((config) => {
  const JWT = getJWT() || null;
  if (config !== undefined) {
    (config.headers as any)["Authorization"] = `Bearer ${JWT}`;
    (config.headers as any)["device_id"] = getDeviceId();
  }
  return config;
});

export default axiosInstance;
