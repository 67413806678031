import { forwardRef } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, size = 'medium', ...other }, ref) => (
    <IconButton size={size} ref={ref} {...other}>
      {children}
    </IconButton>
  )
);

export default IconButtonAnimate;
