import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { getJWT } from "../helpers/local-storage.helper";
import {
  DEFAULT_PAGE_SUPER_ADMIN,
  DEFAULT_PAGE_ADMIN,
  DEFAULT_PAGE_ِEMPLOYEE,
} from "src/config/HeaderSettings";
import useAuth from "src/utils/hooks/useAuth";

interface RedirectIfAuthGuardProps {
  children: ReactNode;
}

const RedirectIfAuthGuard: FC<RedirectIfAuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();

  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  const JWT = getJWT();

  if (JWT != null) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    if (auth.user?.is_super_admin)
      return <Navigate to={DEFAULT_PAGE_SUPER_ADMIN} replace={true} />;
    if (auth.user?.is_admin)
      return <Navigate to={DEFAULT_PAGE_ADMIN} replace={true} />;
    return <Navigate to={DEFAULT_PAGE_ِEMPLOYEE} replace={true} />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

RedirectIfAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default RedirectIfAuthGuard;
