import type { FC, ReactNode } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";

import { crud } from "../api/Crud";
import { useNavigate } from "react-router-dom";
import { IS_THERE_SUPER_ADMIN_API } from "../api/Endpoints";
import { IServerResponse } from "../types/server-response";

interface RedirectToRegisterInterface {
  children: ReactNode;
}

const RedirectToRegister: FC<RedirectToRegisterInterface> = (props) => {
  const navigate = useNavigate();
  const { children } = props;

  useEffect(() => {
    crud
      .getAll({
        url: IS_THERE_SUPER_ADMIN_API,
      })
      .then((res: IServerResponse) => {
        if (!res.data.result) {
          navigate("/register", { replace: true });
        }
      });
  }, [navigate]);

  return <>{children}</>;
};

RedirectToRegister.propTypes = {
  children: PropTypes.node,
};

export default RedirectToRegister;
