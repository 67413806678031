/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo } from "react";
import type { FC } from "react";
import { format } from "date-fns";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumbs from "src/components/Breadcrumbs";
import Container from "src/components/container/Container";
import Table from "src/components/data-table/Table";

const CompaniesList: FC = () => {
  return (
    <Container>
      <Breadcrumbs
        Header="Dashboard"
        Breads={[
          {
            text: "Companies",
            url: "",
          },
        ]}
      />
      <Table
        QueryKey={"super-dashboard/company"}
        keyData={`data.companies`}
        url={`super-dashboard/company`}
        columns={[
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Company Name",
            accessor: "company_name",
            isSortable: true,
          },
          {
            Header: "Company username",
            accessor: "company_username",
            isSortable: true,
          },
          {
            Header: "Email",
            accessor: "email",
            isSortable: true,
          },
          {
            Header: "Status",
            accessor: "status",
            isSortable: true,
          },
          {
            Header: "Register date",
            accessor: "register_date",
            isSortable: true,
            Cell: (props) => {
              return (
                <>{format(new Date(props.value || new Date()), "MM/dd/yyyy")}</>
              );
            },
          },
          {
            Header: "action ",
            accessor: "",
            isSortable: true,
            Cell: (props) => {
              return (
                <>
                  <Link
                    component={RouterLink}
                    to={`/dashboard/companies/edit/${props.row.original.id}`}
                  >
                    Edit
                  </Link>
                </>
              );
            },
          },
        ]}
      />
    </Container>
  );
};

export default memo(CompaniesList);
