import { Box, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material";
// import { getWindowHeight } from 'App';
import Scrollbar from "../Scrollbar";

import React from "react";

type ContainerProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const Container = (props: ContainerProps) => {
  // const height = getWindowHeight();
  return (
    <Scrollbar>
      <Box
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "background.default",
          color: "text.primary",
          flex: 1,
          px: 2,
          py: 1,
          minHeight: "calc(100vh - 78px)",
          ...props?.sx,
          "& > *": {
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "16px",
          }}
          className="first-children"
        >
          {props.children}
        </Box>
        <Typography
          sx={{
            mt: 1.5,
          }}
        >
          COPYRIGHT &copy; {new Date().getFullYear()} HR. All rights reserved.
        </Typography>
      </Box>
    </Scrollbar>
  );
};

export default Container;
