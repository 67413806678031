import { useEffect } from "react";
import type { FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  DEFAULT_PAGE_ADMIN,
  DEFAULT_PAGE_ِEMPLOYEE,
  DEFAULT_PAGE_SUPER_ADMIN,
  NAVBAR,
} from "../../../config/HeaderSettings";
import {
  // Avatar,
  Box,
  // Button,
  // Divider,
  Drawer,
  // Link,
  // Typography,
} from "@mui/material";
// import useAuth from 'hooks/useAuth';
import useCollapseDrawer from "../../../utils/hooks/useCollapseDrawer";
import useResponsive from "../../../utils/hooks/useResponsive";
import CollapseButton from "./CollapseButton";
import {
  ISection,
  AdminSections,
  EmployeeSections,
  superAdminSections,
} from "./Sections";
import Logo from "../../Logo";
import NavSection from "../../NavSection";
import Scrollbar from "../../Scrollbar";
// import { useQuery } from "react-query";
// import { crud } from "../../../utils/api/Crud";
import useAuth from "src/utils/hooks/useAuth";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose = () => {}, openMobile = false } = props;
  const location = useLocation();

  const auth = useAuth();

  const isDesktop = useResponsive("up", "lg");

  const GetSidebarSections = (): ISection[] => {
    if (auth.user?.is_super_admin) {
      return superAdminSections;
    } else if (auth.user?.is_admin) {
      return AdminSections;
    } else {
      return EmployeeSections;
    }
  };

  const {
    isCollapse,
    collapseClick,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (!openMobile && onMobileClose) onMobileClose();
  }, [location.pathname, onMobileClose, openMobile]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            px: "26px",
            mb: "34px",
            mt: "16px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <RouterLink
            to={
              auth.user?.is_super_admin
                ? DEFAULT_PAGE_SUPER_ADMIN
                : auth.user?.is_admin
                ? DEFAULT_PAGE_ADMIN
                : DEFAULT_PAGE_ِEMPLOYEE
            }
          >
            <Logo
              sx={{
                height: "26px",
                width: "126px",
                marginInlineStart: isCollapse ? "-10px" : undefined,
              }}
            />
          </RouterLink>
          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Box>

        <Box sx={{ p: 2 }}>
          {GetSidebarSections().map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              section={section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );
  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            // backgroundColor: 'background.paper',
            backgroundColor: "background.paper",
            width: NAVBAR.DASHBOARD_WIDTH,

            zIndex: 200,
          },
          display: {
            lg: "none",
            xs: "block",
          },
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <Drawer
        open
        onMouseEnter={onHoverEnter}
        onMouseLeave={onHoverLeave}
        PaperProps={{
          sx: {
            zIndex: 200,
            width: NAVBAR.DASHBOARD_WIDTH,
            border: "none",
            bgcolor: "background.paper",
            display: {
              lg: "block",
              xs: "none",
            },
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.standard,
              }),
            ...(isCollapse && {
              width: `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH + 60}px`,
            }),
            ...(!isCollapse && {
              width: NAVBAR.DASHBOARD_WIDTH,
            }),
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};

export default DashboardSidebar;
