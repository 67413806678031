import { useEffect } from "react";
// import ReactDOM from "react-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import {
  Alert,
  //   Grid,
  //   IconButton,
  //   Snackbar,
  //   Typography,
  // , Grid, IconButton, Snackbar, Typography
} from "@mui/material";
// import XIcon from "@mui/icons-material/X";
import { createRoot, Root } from "react-dom/client";

const useDisplaySnackbar = (
  msg: string | undefined,
  variant: "success" | "error" | "warning" | "info" | undefined
) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (msg) {
      const key = enqueueSnackbar(msg, {
        variant,
        autoHideDuration: 6000,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        content: (key) => (
          <Alert
            onClose={() => closeSnackbar(key)}
            severity={variant}
            color={variant}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        ),
      });

      return () => {
        closeSnackbar(key);
      };
    }
  }, [msg, variant, enqueueSnackbar, closeSnackbar]);
};
let root: Root;
const errorHandlingToast = (
  msg: string | undefined,
  variant: "success" | "error" | "warning" | "info" | undefined
) => {
  const mountPoint = document.getElementById(
    "error-handler-snackbar"
  ) as HTMLElement;
  root = root ? root : createRoot(mountPoint);
  root.render(
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <SnackbarRenderer message={msg} variant={variant} />
    </SnackbarProvider>
  );
};

const SnackbarRenderer: React.FC<{
  message: string | undefined;
  variant: "success" | "error" | "warning" | "info" | undefined;
}> = ({ message, variant }) => {
  useDisplaySnackbar(message, variant);
  return null;
};

// const notificationToast = (
//   title: string | undefined,
//   description: string | undefined,
// ) => {
//   const Display = withSnackbar((props: any) => {
//     const [open, setOpen] = React.useState(true);
//
//     useEffect(() => {
//       const audio = new Audio("audios/notification.wav");
//       audio.muted = false;
//       audio
//         .play()
//         .then((res) => {})
//         .catch((error) => {});
//     }, []);
//
//     const handleClose = (event: any, reason: any) => {
//       if (reason === "clickaway") {
//         return;
//       }
//
//       setOpen(false);
//     };
//
//     return (
//       <Snackbar
//         key={props.title}
//         open={open}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ horizontal: "right", vertical: "top" }}
//         sx={{
//           marginTop: 6,
//           maxWidth: 50,
//         }}
//         message={
//           <Grid
//             container
//             direction="row"
//             justifyContent="flex-start"
//             alignItems="center"
//             sx={{}}
//           >
//             <Grid item xs={11}>
//               <Grid
//                 container
//                 direction="row"
//                 justifyContent="flex-start"
//                 alignItems="center"
//               >
//                 <Grid item xs={12}>
//                   <Typography sx={{ color: "primary.main" }} variant="h6">
//                     {props.title}
//                   </Typography>
//                 </Grid>
//
//                 <Grid item xs={12}>
//                   {props.description}
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Grid item xs={1}>
//               <IconButton onClick={handleClose as any}>
//                 <XIcon sx={{ color: "#F44336" }} />
//               </IconButton>
//             </Grid>
//           </Grid>
//         }
//       />
//     );
//   });
//
//   const mountPoint = document.getElementById(
//     "notification-snackbar",
//   ) as HTMLElement;
//   const root = createRoot(mountPoint);
//
//   root.render(
//     <SnackbarProvider
//       maxSnack={3}
//       anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
//     >
//       <Display message={title} variant={description} />
//     </SnackbarProvider>,
//   );
// };

export const showSuccessSnackbarMessage = (msg: string) => {
  errorHandlingToast(msg, "success");
};

export const showErrorSnackbarMessage = (msg: string) => {
  errorHandlingToast(msg, "error");
};

export const showWarningSnackbarMessage = (msg: string) => {
  errorHandlingToast(msg, "warning");
};

// export const showNotification = (
//   title: string | undefined,
//   description: string | undefined,
// ) => {
//   notificationToast(title, description);
// };
