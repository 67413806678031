const SERVER_ERROR_CODES = [
  { code: 0, message: "There was a validation error. Please check your input and try again." },
  { code: 1, message: "No administrator found with this ID." },
  { code: 2, message: "No administrator found with this username." },
  { code: 3, message: "An administrator with this email is already registered." },
  { code: 4, message: "The password you entered is invalid." },
  { code: 5, message: "The password reset attempt failed. The passwords do not match." },
  { code: 6, message: "You cannot delete this administrator as they are the last super admin in the system." },
  { code: 7, message: "The JWT is invalid or has expired." },
  { code: 8, message: "An administrator with this username is already registered." },
  { code: 9, message: "Cannot register a super admin. There is already a super admin registered." },
  { code: 10, message: "You cannot delete this administrator as it is your own account." },
  { code: 11, message: "The authorization header was not found in the request." },
  { code: 12, message: "You are not allowed to perform this action." }
];

const defaultErrorMessage = "Undetected Error!";

export const getErrorMessageByCode = (code: number) => {
  const error = SERVER_ERROR_CODES.find((e) => e.code === code);
  let errorMessage = defaultErrorMessage;
  if (error != null) {
    errorMessage = error.message;
  }
  return errorMessage;
};

export interface errorInterface {
  response: any;
}
