import { getSubDomain } from "../utils/helpers/domain.helper";

export const REACT_APP_QUERY_DEVTOOLS: boolean =
  process.env.REACT_APP_QUERY_DEVTOOLS === "true" || false;
export const REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN =
  process.env.REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN || "super";
export const SERVER_BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:3007/api";
export const isSuperAdmin = () =>
  getSubDomain().includes(REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN);
