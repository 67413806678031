import { SERVER_BASE_URL } from "../../constants";

// Super Auth
const SERVER_BASE_URL_SUPER_AUTH = `${SERVER_BASE_URL}/super-dashboard/auth`;
export const SUPER_LOGIN_API = `${SERVER_BASE_URL_SUPER_AUTH}/login`;
export const SUPER_LOGOUT_API = `${SERVER_BASE_URL_SUPER_AUTH}/logout`;
export const IS_THERE_SUPER_ADMIN_API = `${SERVER_BASE_URL_SUPER_AUTH}/is-there-super-admin`;
export const SUPER_REGISTER_API = `${SERVER_BASE_URL_SUPER_AUTH}/register`;

// Super Profile
const SERVER_BASE_URL_SUPER_PROFILE = `${SERVER_BASE_URL}/super-dashboard/profile`;
export const SUPER_ME_API = `${SERVER_BASE_URL_SUPER_PROFILE}/me`;

// Auth
const SERVER_BASE_URL_AUTH = `${SERVER_BASE_URL}/dashboard/auth`;
export const LOGIN_API = `${SERVER_BASE_URL_AUTH}/login`;
export const LOGOUT_API = `${SERVER_BASE_URL_AUTH}/logout`;
export const SET_PASSWORD_API = `${SERVER_BASE_URL_AUTH}/register-first-admin`;

// Profile
export const SERVER_BASE_URL_PROFILE = `${SERVER_BASE_URL}/dashboard/profile`;
export const PROFILE_ME_API = `${SERVER_BASE_URL_PROFILE}/me`;

//Company
export const SERVER_BASE_URL_COMPANY = `${SERVER_BASE_URL}/super-dashboard/company`;
