import { FC } from "react";
import useAuth from "./utils/hooks/useAuth";
import SplashScreen from "./components/SplashScreen";
import { useRoutes } from "react-router-dom";
import { routes, SuperRoutes, routesAdmin } from "./routes";
import { createTheme } from "./utils/theme";
import { ThemeProvider } from "@mui/material";
import useSettings from "./utils/hooks/useSettings";
import {isSuperAdmin} from "./constants";

const App: FC = () => {
  const getRoute = () => {
    if(isSuperAdmin()) return SuperRoutes;
    if (auth.user?.is_admin) return routesAdmin;
    return routes;
  };
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });
  const auth = useAuth();
  const content = useRoutes(getRoute());
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {auth.isInitialized === true ? content : <SplashScreen />}
      </ThemeProvider>
    </div>
  );
};

export default App;
