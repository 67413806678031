import type { FC } from "react";
import { memo } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Typography, Breadcrumbs, Link, Box, Button } from "@mui/material";
import Dashboard from "src/icons/Dashboard";
import Dot from "src/icons/Dot";
import useAuth from "src/utils/hooks/useAuth";
import {
  DEFAULT_PAGE_ADMIN,
  DEFAULT_PAGE_SUPER_ADMIN,
  DEFAULT_PAGE_ِEMPLOYEE,
} from "src/config/HeaderSettings";

interface IBreads {
  text: string;
  url?: string;
}
interface Props {
  Breads: IBreads[];
  Header: string;
  perfix?: string;
  permission?: string | string[];
  addOneButton?: {
    link: string;
    text: string;
  };
}

const MyBreadcrumbs: FC<Props> = ({
  Breads,
  Header,
  addOneButton,
  perfix,
  permission,
}) => {
  const auth = useAuth();

  return (
    <Box
      role="presentation"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          // variant='h1'
          sx={{
            color: "primary.main",
            fontSize: "24px !important",
            fontWeight: 600,
            mb: 1,
          }}
        >
          {Header}
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <Dot
              sx={{
                color: "rgba(24,200,200,0.6)",
                height: "16px",
                width: "16px",
              }}
            />
          }
        >
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href={
              auth.user?.is_super_admin
                ? DEFAULT_PAGE_SUPER_ADMIN
                : auth.user?.is_admin
                ? DEFAULT_PAGE_ADMIN
                : DEFAULT_PAGE_ِEMPLOYEE
            }
          >
            <Dashboard
              sx={{ mr: 0.5, color: "primary.main" }}
              fontSize="inherit"
            />
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Dashboard
            </Typography>
          </Link>
          {Breads.map((item, index) => {
            return (
              <Box key={`Breadcrumbs-${index}`} component={"span"}>
                {index + 1 !== Breads.length && (
                  <Link
                    underline="hover"
                    sx={{ display: "flex", alignItems: "center" }}
                    color="inherit"
                    component={ReactRouterLink}
                    to={
                      perfix !== undefined
                        ? `${perfix}${item.url}`
                        : `/dashboard${item.url}`
                    }
                  >
                    <Typography
                      sx={{ display: "flex", alignItems: "center" }}
                      color="text.primary"
                    >
                      {item.text}
                    </Typography>
                  </Link>
                )}
                {index + 1 === Breads.length && (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "rgba(0,0,0,0.5)",
                    }}
                  >
                    {item.text}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Breadcrumbs>
      </Box>
      {addOneButton && (
        <Link
          underline="none"
          component={ReactRouterLink}
          to={
            perfix !== undefined
              ? //@ts-ignore
                `${perfix}/${addOneButton.link}`
              : //@ts-ignore
                `/dashboard/${addOneButton.link}`
          }
        >
          <Button
            sx={{
              py: 1.5,
              px: {
                sm: 5,
                xs: 1.5,
              },
              borderRadius: "66666px",
            }}
            color="primary"
            variant="contained"
          >
            {/* @ts-ignore */}
            {addOneButton.text}
          </Button>
        </Link>
      )}
    </Box>
  );
};

export default memo(MyBreadcrumbs);
