import { FC } from "react";
import { Suspense, lazy } from "react";
import type { RouteObject } from "react-router";
import DashboardLayout from "src/components/layouts/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/utils/guards/AuthGuard";
import RedirectIfAuthGuard from "src/utils/guards/RedirectIfAuthGuard";
import RedirectToRegister from "src/utils/guards/RedirectToRegister";
import RedirectToLogin from "src/utils/guards/RedirectToLogin";
import { EditCompany } from "src/pages/company/edit";
import { AddCompany } from "src/pages/company/add";
import CompaniesList from "src/pages/company/list";

const Loadable = (Component: FC) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages
const Login = Loadable(lazy(() => import("src/pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("src/pages/authentication/Register"))
);

const SetPassword = Loadable(
  lazy(() => import("src/pages/authentication/SetPassword"))
);

// const Dashboard = Loadable(lazy(() => import("src/pages/dashboard")));
const SuperAdminDashboard = Loadable(
  lazy(() => import("src/pages/super-admins-dashboard"))
);

// admin
const Employees = Loadable(lazy(() => import("src/pages/employees")));
const AddEmployees = Loadable(lazy(() => import("src/pages/add-employees")));
const AddTask = Loadable(lazy(() => import("src/pages/add-task")));
const Tasks = Loadable(lazy(() => import("src/pages/tasks")));
const EditEmployees = Loadable(lazy(() => import("src/pages/edit-employees")));
const Clock = Loadable(lazy(() => import("src/pages/clock")));
const TimeSheet = Loadable(lazy(() => import("src/pages/time-sheet")));
const Files = Loadable(lazy(() => import("src/pages/files")));
const FilesDetails = Loadable(lazy(() => import("src/pages/file-details")));
const FilesEmployee = Loadable(lazy(() => import("src/pages/files-employee")));

// profile
const ProfileEdit = Loadable(lazy(() => import("src/pages/my-profile")));
const Profile = Loadable(lazy(() => import("src/pages/preview-profile")));
const ProfileSuperAdmin = Loadable(
  lazy(() => import("src/pages/super-admin-profile"))
);

const NotFound = Loadable(lazy(() => import("src/pages/not-found")));

const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: "login",
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
      {
        path: "/",
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
    ],
  },
  {
    path: "/set_password/:user_id/:code",
    element: (
      <RedirectIfAuthGuard>
        <SetPassword />
      </RedirectIfAuthGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Clock />,
      },
      {
        path: "profile/edit",
        element: <ProfileEdit />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "task/add",
        element: <AddTask />,
      },

      {
        path: "time-sheet",
        element: <TimeSheet />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "files",
        element: <Files />,
      },
      {
        path: "files/details/:id",
        element: <FilesDetails />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const routesAdmin: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: "login",
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
      {
        path: "/",
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
    ],
  },
  {
    path: "/set_password/:user_id/:code",
    element: (
      <RedirectIfAuthGuard>
        <SetPassword />
      </RedirectIfAuthGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Clock />,
      },
      {
        path: "profile/edit",
        element: <ProfileEdit />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "employee",
        element: <Employees />,
      },
      {
        path: "employee/add",
        element: <AddEmployees />,
      },
      {
        path: "employee/edit/:id",
        element: <EditEmployees />,
      },
      {
        path: "time-sheet",
        element: <TimeSheet />,
      },
      {
        path: "time-sheet/:id",
        element: <TimeSheet />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "tasks/:id",
        element: <Tasks />,
      },
      {
        path: "task/add",
        element: <AddTask />,
      },
      {
        path: "files",
        element: <Files />,
      },
      {
        path: "files/details/:id",
        element: <FilesDetails />,
      },
      {
        path: "files/:id",
        element: <FilesEmployee />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const SuperRoutes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: "/",
        element: (
          <RedirectIfAuthGuard>
            <RedirectToRegister>
              <Login />
            </RedirectToRegister>
          </RedirectIfAuthGuard>
        ),
      },
      {
        path: "login",
        element: (
          <RedirectIfAuthGuard>
            <RedirectToRegister>
              <Login />
            </RedirectToRegister>
          </RedirectIfAuthGuard>
        ),
      },
      {
        path: "register",
        element: (
          <RedirectIfAuthGuard>
            <RedirectToLogin>
              <Register />
            </RedirectToLogin>
          </RedirectIfAuthGuard>
        ),
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SuperAdminDashboard />,
      },
      {
        path: "profile",
        element: <ProfileSuperAdmin />,
      },
      {
        path: "companies",
        element: <CompaniesList />,
      },
      {
        path: "companies/add",
        element: <AddCompany />,
      },
      {
        path: "companies/edit/:id",
        element: <EditCompany />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
export { routes, SuperRoutes, routesAdmin };
