import * as deviceDetect from "react-device-detect";
import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import axios from "../api/axios";
import { crud } from "../api/Crud";
import type { User } from "../types/user";
import pureAxios from "axios";
import {
  showSuccessSnackbarMessage,
  showErrorSnackbarMessage,
} from "../helpers/snackbar.helper";
import { useNavigate } from "react-router-dom";
import { useBattery, useNetworkState } from "react-use";
// import { PermissionMatch } from "./PermissionMatch";
import { isSuperAdmin } from "../../constants";
import {
  LOGIN_API,
  LOGOUT_API,
  PROFILE_ME_API,
  SUPER_LOGIN_API,
  SUPER_LOGOUT_API,
  SUPER_ME_API,
} from "../api/Endpoints";
import { getJWT, removeJWT, setJWT } from "../helpers/local-storage.helper";
import { IServerResponse } from "../types/server-response";
import {
  DEFAULT_PAGE_ADMIN,
  DEFAULT_PAGE_SUPER_ADMIN,
  DEFAULT_PAGE_ِEMPLOYEE,
} from "../../config/HeaderSettings";
import { getSubDomain } from "../helpers/domain.helper";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  allowsRoutes: any[];
}

interface AuthContextValue extends State {
  platform: "JWT";
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
  Me: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    user: User;
  };
};

type GetMeAction = {
  type: "ME";
  payload: {
    user: User;
    allowsRoutes: any[];
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | GetMeAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  allowsRoutes: [],
  user: null,
};
const setSession = (JWT: string): void => {
  axios.defaults.headers.common.Authorization = `Bearer ${JWT}`;
};
const handlers: Record<
  string,
  (state?: State | any, action?: Action | any) => State
> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  ME: (state: State, action: GetMeAction): State => {
    const { user, allowsRoutes } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      allowsRoutes,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  Me: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const navigate = useNavigate();
  const battery = useBattery() as any;
  const network = useNetworkState();
  // const geolocation = useGeolocation();

  const getMe = async () => {
    try {
      if (!!getJWT()) {
        const response: IServerResponse = await crud.getAll({
          url: isSuperAdmin() ? SUPER_ME_API : PROFILE_ME_API,
        });
        dispatch({
          type: "ME",
          payload: {
            user: response.data,
            allowsRoutes: [], //PermissionMatch(response.data.permission_keys),
          },
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const JWT = getJWT();

        if (JWT) {
          setSession(JWT);
          await getMe();
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    try{const resIp = await pureAxios.get(`https://ipapi.co/json/`);
    const sub_domain = getSubDomain();
    const res: IServerResponse = await crud.addOne({
      url: isSuperAdmin() ? SUPER_LOGIN_API : LOGIN_API,
      params: {
        email,
        password,
        company_username: sub_domain,

        device_details: {
          device_id: "",
          device_name: deviceDetect.engineName,
          device_model: deviceDetect.mobileModel,
          device_type: deviceDetect.deviceType,
          os_version: deviceDetect.osVersion,
          full_browser_version: deviceDetect.fullBrowserVersion,
          ip_address: resIp?.data?.ip,
          region: "",
          time_zone: "",
          mobile_carrier: "",
          screen_resolution: `${window?.screen?.availHeight}x${window?.screen?.availWidth}`,
          battery_level: battery?.level || "",
          battery_state: battery?.charging || "",
          network_type: network?.effectiveType || "",
          mobile_country_code: "",
          mobile_network_code: "",
          vpn_connected: true,
        },
      },
    });
    if (res.data.token) {
      setJWT(res.data.token);
      setSession(res.data.token);
    }
    if (res.data.user) {
      dispatch({
        type: "LOGIN",
        payload: {
          user: res.data.user as any,
        },
      });
      let route = "";
      if (res.data.user.is_super_admin) {
        route = DEFAULT_PAGE_SUPER_ADMIN;
      }
      if (res.data.user.is_admin) {
        route = DEFAULT_PAGE_ADMIN;
      }
      if (!res.data.user.is_admin && !res.data.user.is_super_admin) {
        route = DEFAULT_PAGE_ِEMPLOYEE;
      }
      navigate(route, { replace: true });
      showSuccessSnackbarMessage("login done");
    } else {
      showErrorSnackbarMessage("not authorized");
    }}catch(err){
      showErrorSnackbarMessage((err as any)?.response?.data?.message ?? "not authorized");
    }
  };

  const logout = async (): Promise<void> => {
    // setSession(null);
    await crud.addOne({
      url: isSuperAdmin() ? SUPER_LOGOUT_API : LOGOUT_API,
    });
    await dispatch({ type: "LOGOUT" });
    removeJWT();
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ JWT: string; user: User }>(
      "/api/authentication/register",
      {
        email,
        name,
        password,
      }
    );
    const { JWT, user } = response.data;

    setJWT(JWT);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
        Me: getMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
