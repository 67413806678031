import { createSvgIcon } from "@mui/material/utils";

const Files = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M338 0H103.3v426.7h384V149.3H338zm21.3 0v128h128zM60.7 85.3H18V512h384v-42.7H60.7z"
    ></path>
  </svg>,
  "Files"
);

export default Files;
