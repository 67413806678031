import axiosInstance from "./axios";
import { showSuccessSnackbarMessage } from "../helpers/snackbar.helper";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import useAuth from "../hooks/useAuth";
import { removeJWT } from "../helpers/local-storage.helper";
import { IServerResponse } from "../types/server-response";

export const crud = {
  getAll: async ({
    url,
    params,
  }: {
    url: string;
    params?: any;
  }): Promise<IServerResponse> => axiosInstance.get(url, { params }),
  getOne: async ({
    url,
    params,
    id,
  }: {
    url: string;
    params?: any;
    id?: string;
  }): Promise<IServerResponse> =>
    axiosInstance.get(`${url}/${id}`, { ...params }),
  deleteOne: async ({
    url,
    params,
    id,
  }: {
    url: string;
    params?: any;
    id?: string;
  }): Promise<IServerResponse> =>
    axiosInstance.delete(`${url}/${id}`, { ...params }),
  delete: async ({
    url,
    params,
    id,
  }: {
    url: string;
    params?: any;
    id?: string;
  }): Promise<IServerResponse> => axiosInstance.delete(`${url}`, { ...params }),
  addOne: async ({
    url,
    params,
  }: {
    url: string;
    params?: any;
  }): Promise<IServerResponse> => axiosInstance.post(url, { ...params }),
  editOne: async ({
    url,
    params,
    id,
  }: {
    url: string;
    params?: any;
    id?: string;
  }): Promise<IServerResponse> =>
    axiosInstance.put(`${url}/${id}`, { ...params }),
  edit: async ({
    url,
    params,
  }: {
    url: string;
    params?: any;
  }): Promise<IServerResponse> => axiosInstance.put(url, { ...params }),
};

interface Props {
  id: string;
  method: "addOne" | "deleteOne" | "edit" | "editOne" | "getOne" | "getAll";
  queryKey: any;
  dataKey: string;
  url: string;
  toastMessage: string;
  gotoAfterOnSuccess?: string;
}

export const useMutationOnArray = ({
  id,
  method,
  url,
  dataKey,
  queryKey,
  toastMessage,
  gotoAfterOnSuccess,
}: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const auth = useAuth();

  const Mutation = useMutation(
    //@ts-ignore
    (params) => crud[method]({ url, id, params: { ...params } }),
    {
      onSuccess: (data: any) => {
        if (method === "addOne") {
          const newData = _.get(data, dataKey, undefined);

          queryClient.setQueryData(queryKey, (old: any) => {
            showSuccessSnackbarMessage(toastMessage);
            if (old === undefined)
              navigate(gotoAfterOnSuccess as string, { replace: true });
            old.data.push(newData);
            return old;
          });
        }
        if (method === "editOne") {
          const newData = _.get(data, dataKey, undefined);
          queryClient.setQueryData(queryKey, (old: any) => {
            if (old === undefined) navigate(gotoAfterOnSuccess as string);
            const res = old.data.map((item: any) => {
              showSuccessSnackbarMessage(toastMessage);
              if (item.id === id) return newData;
              return item;
            });
            old.data = res;

            return old;
          });
        }
        if (method === "edit") {
          showSuccessSnackbarMessage(toastMessage);
          console.log("datadata:\n", data);
          console.log("auth:\n", auth?.user);

          if (
            auth?.user?.username !== data?.data?.username ||
            auth?.user?.email !== data?.data?.email
          ) {
            removeJWT();
            window.location.href = "/login";
          }

          return data;
        }
        if (method === "deleteOne") {
          queryClient.setQueryData(queryKey, (old: any) => {
            const res = old.data.filter((item: any) => item.id !== id);
            old.data = res;
            showSuccessSnackbarMessage(toastMessage);

            return old;
          });
        }
      },
      onError: (error) => {
        console.log({ error });
      },
    }
  );
  return Mutation;
};
