import { createSvgIcon } from '@mui/material/utils';

const Bell = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16.259'
    height='18.606'
    viewBox='0 0 16.259 18.606'
  >
    <g id='notification' transform='translate(-16 -12)'>
      <path
        id='Path_2440'
        data-name='Path 2440'
        d='M33.3,57a2.347,2.347,0,1,0,4.231,0Z'
        transform='translate(-11.289 -29.757)'
        fill='#4c4d4e'
        fillRule='evenodd'
      />
      <path
        id='Path_2441'
        data-name='Path 2441'
        d='M24,17a5.42,5.42,0,0,0-3.832,1.587l-.213.213a5.42,5.42,0,0,0-1.587,3.832v1a6.9,6.9,0,0,1-2.02,4.877,1.2,1.2,0,0,0,.847,2.044H31.061a1.2,1.2,0,0,0,.847-2.044,6.9,6.9,0,0,1-2.02-4.877v-1A5.42,5.42,0,0,0,28.3,18.8l-.213-.213A5.42,5.42,0,0,0,24.256,17Z'
        transform='translate(0 -3.306)'
        fill='#bababa'
        fillRule='evenodd'
      />
      <path
        id='Path_2442'
        data-name='Path 2442'
        d='M39.355,13.722V12.677a.677.677,0,1,0-1.355,0v1.044a5.423,5.423,0,0,1,.551-.028H38.8A5.423,5.423,0,0,1,39.355,13.722Z'
        transform='translate(-14.548)'
        fill='#4c4d4e'
        fillRule='evenodd'
      />
    </g>
  </svg>,
  'Bell'
);

export default Bell;
