import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./utils/contexts/JWTContext";
import { BrowserRouter } from "react-router-dom";
import { REACT_APP_QUERY_DEVTOOLS } from "./constants";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import { StrictMode } from "react";
import { CollapseDrawerProvider } from "./utils/contexts/CollapseDrawerContext";
import { HelmetProvider } from "react-helmet-async";
import { StyledEngineProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import "yet-another-react-lightbox/styles.css";
import "react-phone-input-2/lib/material.css";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <AuthProvider>
                  {REACT_APP_QUERY_DEVTOOLS && <ReactQueryDevtools />}
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </QueryClientProvider>
    <div id="error-handler-snackbar" />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
