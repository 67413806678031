/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/indent */
import type { FC } from "react";
import { Fragment } from "react";
import get from "lodash/get";
import { useMemo, useReducer, useEffect } from "react";
import {
  // TablePagination,
  Skeleton,
  Box,
  SxProps,
  Theme,
  Typography,
  Card,
} from "@mui/material";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
} from "react-table";
import {
  TextFilterBackend,
  SelectFilterBackEnd,
  DatePickerFilterBackend,
} from "./FilteresBackend";
import TableContainer from "./TableContainer";
import { useQuery } from "react-query";
import axiosInstance from "src/utils/api/axios";
import { SelectFilter, TextFilter } from "./Filteres";
import { useSearchParams } from "react-router-dom";
import { getAllQuery } from "src/utils/helpers/query-string-url.helper";
import { SERVER_BASE_URL } from "src/constants";

interface BackendFilter {
  type: string;
  option: {
    label: string;
    value: string | number;
  }[];
  onChange: (params: any) => void;
  withAll?: boolean;
  values: any;
  name: any;
  placeholder: any;
  label: any;
  sx: SxProps<Theme>;
}
interface Column {
  isSortable?: Boolean;
  Cell?: (props: any) => JSX.Element;
  Filter?: any;
  options?: any;
  isDummy?: Boolean;
  label?: string;
  Header?: string | ((props: any) => JSX.Element);
  accessor?: string;
  placeholder?: string;
  orderFilter?: number;
  hideHeader?: boolean;
  isVisible?: boolean;
  sx?: SxProps<Theme>;
  sxHeader?: SxProps<Theme>;
}

interface Props {
  columns: Column[];
  data?: any;
  QueryKey?: any;
  url?: string;
  filterComeFromBackend?: boolean;
  BackendFilters?: Partial<BackendFilter>[];
  FiltersValues?: any;
  keyData?: string;
  setValues?: Function | undefined;
  actionFunctions?: any;
  setQueryKey?: any;
  isHiddenHeader?: boolean;
}

const Table: FC<Props> = ({
  columns,
  data,
  QueryKey,
  url,
  filterComeFromBackend = false,
  BackendFilters,
  FiltersValues,
  keyData,
  actionFunctions,
  setQueryKey,
  isHiddenHeader,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params, updateParams] = useReducer(
    (state: any, values: any) => ({ ...state, ...values }),
    {
      limit: 100,
      page: 0,
      ...getAllQuery(),
    }
  );

  const backendSetPage = (page: number) => updateParams({ page });
  const backendSetPageSize = (size: number) =>
    updateParams({ page: 0, limit: size });

  const {
    data: dataQuery,
    isLoading,
    isFetched,
  } = useQuery(
    [QueryKey, params, FiltersValues],
    () =>
      axiosInstance.get(`${SERVER_BASE_URL}/${url}`, {
        params: {
          ...params,
          ...FiltersValues,
          ...getAllQuery(),
        },
      }),
    {
      enabled: data === undefined,
    }
  );

  const memoData = useMemo(() => {
    if (isFetched) {
      return get(dataQuery, keyData as string, undefined);
    } else if (dataQuery?.data?.data !== undefined) {
      return dataQuery?.data?.data;
    } else if (data !== undefined) return data;
    else return [];
  }, [get(dataQuery, keyData as string, undefined), data, url]);

  useEffect(() => {
    if (setQueryKey) setQueryKey([QueryKey, params, FiltersValues]);
  }, [params, FiltersValues]);

  useEffect(() => {
    if (searchParams.get("page") === "0") backendSetPage(0);
  }, [searchParams.get("page")]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //@ts-ignore
    page,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    setPageSize,
    //@ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      //@ts-ignore
      columns,
      data: memoData,
      initialState: {
        hiddenColumns: [],
        //@ts-ignore
        pageSize: searchParams.get("limit") || 100,
        // Filters: { id: 'content', value: text },
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  if (data === undefined && memoData === undefined)
    return (
      <>
        {Array(15)
          .fill(0)
          .map((_, index) => (
            <Skeleton key={`loading-${index}`} />
          ))}
      </>
    );

  // Render the UI for your table
  return (
    <Box
      sx={{
        p: 0,
        borderRadius: 0,
        bgcolor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 3,
          flexDirection: {
            sm: "row",
            xs: "column",
          },
          p: 2,
        }}
      >
        {BackendFilters?.map((item) => {
          return (
            <Fragment key={`key-${item.name}`}>
              {item.type === "select" && (
                <SelectFilterBackEnd
                  withAll={item.withAll}
                  label={item.label}
                  name={item.name}
                  options={item.option}
                  sx={item.sx}
                  setValues={(value: any) => {
                    (item as any).onChange(value);
                  }}
                  values={item.values}
                />
              )}
              {item.type === "text" && (
                <TextFilterBackend
                  name={item.name}
                  setValues={(value: any) => {
                    (item as any).onChange(value);
                  }}
                  values={item.values}
                  placeholder={item.placeholder}
                  sx={item.sx}
                  label={item.label}
                />
              )}

              {item.type === "day-picker" && (
                <DatePickerFilterBackend
                  name={item.name}
                  label={item.label}
                  placeholder={item.placeholder}
                  values={item.values}
                  setValues={(value: any) => {
                    (item as any).onChange(value);
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </Box>

      {isLoading === true && (
        <>
          {Array(15)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={`loading-${index}`} />
            ))}
        </>
      )}
      {memoData?.length === 0 && isLoading === false && <></>}
      {memoData?.length !== 0 &&
        isLoading === false &&
        (FiltersValues?.view === "list" ||
          FiltersValues?.view === undefined) && (
          <TableContainer
            page={page}
            prepareRow={prepareRow}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            getTableProps={getTableProps}
            isHiddenHeader={isHiddenHeader}
          />
        )}

      {/* <TablePagination
        sx={{
          my: "10px",
          "& p": {
            marginBottom: 0,
          },
        }}
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        //@ts-ignore
        count={
          data !== undefined
            ? Number(data?.length)
            : filterComeFromBackend === false
            ? Number(dataQuery?.data?.data?.length) || 0
            : Number(dataQuery?.data?.data?.count)
        }
        //@ts-ignore
        rowsPerPage={
          filterComeFromBackend === false
            ? Number(pageSize)
            : Number(params.limit)
        }
        //@ts-ignore
        page={
          filterComeFromBackend === false
            ? Number(pageIndex)
            : Number(params.page)
        }
        onPageChange={(v: unknown, MUInewPage: number) => {
          //@ts-ignore
          setSearchParams({
            ...getAllQuery(),
            page: Number(MUInewPage),
          });
          backendSetPage(Number(MUInewPage));
          gotoPage(Number(MUInewPage));
        }}
        onRowsPerPageChange={(e) => {
          //@ts-ignore
          setSearchParams({
            ...getAllQuery(),
            page: 0,
            limit: Number(e.target.value),
          });
          backendSetPageSize(Number(e.target.value));
          setPageSize(Number(e.target.value));
        }}
      /> */}
    </Box>
  );
};

export default Table;

export { TextFilter, SelectFilter };
