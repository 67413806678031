import { useRef, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  alpha,
  Avatar,
  // Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  // ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
// import Settings from "../../../icons/Settings";
import Account from "../../../icons/Account";
// import { showSuccessSnackbarMessage } from "../../../utils/helpers/snackbar.helper";
import useAuth from "../../../utils/hooks/useAuth";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const auth = useAuth();

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    const onLogoutSuccess = async () => {
      await auth.logout();
      // showSuccessSnackbarMessage("Logout successfully");
    };

    try {
      await onLogoutSuccess();
      handleClose();
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          columnGap: "16px",
          p: 1,
          borderRadius: "6666px",
        }}
      >
        {!auth.user?.profile_photo ? (
          <Account />
        ) : (
          <Avatar src={auth.user?.profile_photo} />
        )}
        {/*<Settings />*/}
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240, borderRadius: "8px" },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {auth?.user?.full_name}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <MenuItem component={RouterLink} to="/dashboard/profile">
            <ListItemText
              primary={<Typography color="textPrimary">Profile</Typography>}
            />
          </MenuItem>

          {/*<MenuItem component={RouterLink} to="/dashboard/app-settings">*/}
          {/*  <ListItemText*/}
          {/*    primary={<Typography color="textPrimary">Settings</Typography>}*/}
          {/*  />*/}
          {/*</MenuItem>*/}
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            sx={{
              height: "44px",
              borderRadius: "8px",
              color: "primary.main",
              background: (theme) => alpha(theme.palette.primary.main, 0.1),
              display: "flex",
              justifyContent: "flex-start",
              px: "12px !important",
              border: "none",
            }}
            fullWidth
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
