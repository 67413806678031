// import { useEffect } from "react";
import { Box, Button, Card, TextField, Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  showSuccessSnackbarMessage,
  showErrorSnackbarMessage,
} from "../../utils/helpers/snackbar.helper";
import { crud } from "../../utils/api/Crud";
import useAuth from "../../utils/hooks/useAuth";
// import {useParams} from "react-router-dom";
import { SERVER_BASE_URL_COMPANY } from "../../utils/api/Endpoints";
import { ICompany } from "../../utils/types/company";
import Container from "src/components/container/Container";
import Breadcrumbs from "src/components/Breadcrumbs";

export const AddCompany = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    // setValue,
    formState: { errors, touchedFields, isSubmitting },
  } = useForm({});

  const { mutateAsync } = useMutation(
    (fields: ICompany) =>
      crud.addOne({
        url: `${SERVER_BASE_URL_COMPANY}`,
        params: {
          company_name: fields?.company_name,
          company_username: fields.company_username,
          first_name: fields.first_name,
          last_name: fields.last_name,
          email: fields.email,
        },
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData([SERVER_BASE_URL_COMPANY], () => {
          if (data) {
            showSuccessSnackbarMessage("Add Done");
            return data;
          } else {
            showErrorSnackbarMessage("Add Error");
          }
        });
      },
      onError: async (error: any) => {
        const errors = Object.entries(error.response.data.validation_errors);
        if (errors.length)
          for (const [key, value] of errors) {
            await setFocus(key as any);
            await setError(key as any, { message: value as any });
          }
        else {
          showErrorSnackbarMessage(error.response.data.message);
        }
      },
    }
  );

  const onSubmit = async (_values: any): Promise<void> => {
    try {
      await mutateAsync({ ..._values });
    } catch (error) {
      console.error("Caught error during mutation:", error);
    }
  };

  const permissionUpdate = auth.user?.is_super_admin;

  return (
    <Container>
      <Breadcrumbs
        Header="Add company"
        Breads={[
          {
            text: "Add company",
            url: "",
          },
        ]}
      />
      <Card
        sx={{
          maxWidth: "500px",
          p: 0,
          px: 2,
          pb: 2,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Box sx={{ flex: 1, width: "100%" }}>
              <TextField
                {...register("email", { required: true })}
                error={Boolean(touchedFields.email && errors.email)}
                fullWidth
                helperText={touchedFields.email && errors.email?.message}
                label="Email"
                margin="normal"
                type="text"
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: 1, width: "100%" }}>
              <TextField
                {...register("company_name", { required: true })}
                error={Boolean(
                  touchedFields.company_name && errors.company_name
                )}
                fullWidth
                helperText={
                  touchedFields.company_name && errors.company_name?.message
                }
                label="Company Name"
                margin="normal"
                type="text"
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: 1, width: "100%" }}>
              <TextField
                {...register("first_name", { required: true })}
                error={Boolean(touchedFields.first_name && errors.first_name)}
                fullWidth
                helperText={
                  touchedFields.first_name && errors.first_name?.message
                }
                label="First Name"
                margin="normal"
                type="text"
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: 1, width: "100%" }}>
              <TextField
                {...register("last_name", { required: true })}
                error={Boolean(touchedFields.last_name && errors.last_name)}
                fullWidth
                helperText={
                  touchedFields.last_name && errors.last_name?.message
                }
                label="Last Name"
                margin="normal"
                type="text"
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: 1, width: "100%" }}>
              <TextField
                {...register("company_username", { required: true })}
                error={Boolean(
                  touchedFields.company_username && errors.company_username
                )}
                fullWidth
                helperText={
                  touchedFields.company_username &&
                  errors.company_username?.message
                }
                label="Company Username"
                margin="normal"
                type="text"
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tooltip
                title={
                  permissionUpdate ? "" : "You don’t have permission to do that"
                }
              >
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !permissionUpdate}
                    type="submit"
                    sx={{
                      py: 1.5,
                      px: 5,
                      borderRadius: "666px",
                    }}
                  >
                    Add
                  </Button>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </form>
      </Card>
    </Container>
  );
};
