import { createSvgIcon } from "@mui/material/utils";

const Search = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.366"
    height="22.23"
    viewBox="0 0 22.366 22.23"
  >
    <g id="search" transform="translate(0.559 0.559)">
      <path
        id="Path_2446"
        data-name="Path 2446"
        d="M13.074,19.769a9.469,9.469,0,1,1,2.451,9.147A9.469,9.469,0,0,1,13.074,19.769Z"
        transform="translate(-12.751 -12.751)"
        fill="#161819"
      />
      <path
        id="Path_2447"
        data-name="Path 2447"
        d="M33.851,33.714l-4.867-4.867m-13.459.068a9.469,9.469,0,1,1,9.147,2.451A9.469,9.469,0,0,1,15.524,28.916Z"
        transform="translate(-12.751 -12.751)"
        fill="none"
        stroke="#bababa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>,
  "Search"
);

export default Search;
