import { createSvgIcon } from '@mui/material/utils';

const Dashboard = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='20.5' height='20.564' viewBox='0 0 20.5 20.564'>
    <g id='dashboard' transform='translate(0.75 0.75)'>
      <path
        id='Path_33966'
        d='M0,4.989,2.948,1.1,6.312,3.78,9.2,0'
        transform='translate(4.467 7.442)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <circle
        id='Ellipse_741'
        cx='1.922'
        cy='1.922'
        r='1.922'
        transform='translate(15.156)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path
        id='Path'
        d='M12.054,0H4.842C1.853,0,0,2.116,0,5.105v8.021c0,2.989,1.817,5.1,4.842,5.1h8.539c2.989,0,4.842-2.107,4.842-5.1V6.141'
        transform='translate(0 0.842)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </g>
  </svg>,
  'Dashboard'
);

export default Dashboard;
