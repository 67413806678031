import { REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN } from "../../constants";

export const getSubDomain = () => {
  const url = new URL(window.location.href);
  const hostname = url.hostname;
  if (hostname === "localhost") return REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN;
  if (url.href.includes("localhost")) {
    return url.href.split(".localhost")[0].replace("http://", "");
  }
  const domainParts = hostname.split(".");
  // Assuming the domain is of the form subdomain.domain.tld
  // If there is no subdomain, the length will be 2 (e.g., domain.com)
  if (domainParts.length === 1) return REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN;
  if (domainParts.length > 2)
    return domainParts.slice(0, domainParts.length - 2).join(".");
  return url.href;
};
