import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { getJWT } from "../helpers/local-storage.helper";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;

  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null,
  );
  const navigate = useNavigate();

  const JWT = getJWT();

  if (JWT == null) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    navigate("/login", { replace: true });
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
