import type { FC } from "react";
import { memo, Fragment } from "react";

import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import _ from "lodash";
import Scrollbar from "src/components/Scrollbar";

interface Props {
  getTableProps: any;
  headerGroups: any;
  getTableBodyProps: any;
  prepareRow: any;
  page: any;
  isHiddenHeader?: boolean;
}

const MyContainerTable: FC<Props> = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  prepareRow,
  page,
  isHiddenHeader,
}) => (
  <TableContainer style={{}} component={Scrollbar as any}>
    <Table stickyHeader aria-label="sticky table" {...getTableProps()}>
      {!isHiddenHeader && (
        <TableHead>
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, index: any) => (
                <Fragment key={`table-key-${index}`}>
                  {!column.isSortable && (
                    <TableCell
                      {...column.getHeaderProps()}
                      sx={{
                        bgcolor: "#000",
                        color: "#fff",
                        whiteSpace: "nowrap",
                        ...column?.sxHeader,
                      }}
                    >
                      {column.render("Header")}
                    </TableCell>
                  )}
                  {column.isSortable && (
                    <TableCell
                      {...column.getHeaderProps()}
                      sortDirection={column.isSorted}
                      sx={{
                        bgcolor: "#000",
                        color: "#fff",
                        whiteSpace: "nowrap",
                        ...column?.sxHeader,
                      }}
                    >
                      <TableSortLabel
                        direction={column.isSortedDesc ? "desc" : "asc"}
                        active={column.isSorted}
                        {...column.getSortByToggleProps()}
                      >
                        {column.render("Header")}
                      </TableSortLabel>
                    </TableCell>
                  )}
                </Fragment>
              ))}
            </TableRow>
          ))}
        </TableHead>
      )}
      <TableBody {...getTableBodyProps()}>
        {page.map((row: any) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <TableCell
                    sx={{ background: "#fff" }}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

export default memo(MyContainerTable, (prev, next) =>
  _.isEqual(prev.page, next.page)
);
